import React, { useState } from "react";
import "./Home.css";
import { Carousel } from "react-responsive-carousel";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { BsChevronDoubleDown } from "react-icons/bs";
import { BiFoodMenu } from "react-icons/bi";
import { SiZomato } from "react-icons/si";
import logo from "../../logo.png";
import quality from "../../quality.png";
import convi from "../../convi.png";
import value from "../../value.png";
import variety from "../../variety.png";
import zomatoBike from "../../zomatoBike.png";
// carousel styles
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Home() {
  const createCarouselItemImage = (index, options = {}) => (
    <div key={index}>
      <img src={`/assets/${index}.jpeg`} />
      <p className="legend">Legend {index}</p>
    </div>
  );

  const [show, setShow] = useState(false);

  const baseChildren = (
    <div>{[1, 2, 3, 4, 5].map(createCarouselItemImage)}</div>
  );

  const tooglesGroupId = "Toggles";
  const valuesGroupId = "Values";
  const mainGroupId = "Main";

  const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: true,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: true,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: true,
    emulateTouch: true,
    autoFocus: false,
    thumbWidth: 100,
    selectedItem: 0,
    interval: 2000,
    transitionTime: 500,
    swipeScrollTolerance: 5,
    ariaLabel: undefined,
  });

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const navbarHeight = 600; // Adjust this value based on your navbar height
    const offset = section.getBoundingClientRect().top - navbarHeight;
    section.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div id="home" className="home">
      <div className="banner">
        <img src={logo} alt="Arogya Cafe" className="banner-logo" />
      </div>

      <div>
        <h1 className="aboutUs-title">ABOUT US</h1>
        <div id="aboutUs" className="aboutUs">
          {/* <div> */}
          <div className="aboutUs_container">
            <p className="aboutUS_text">
              Arogya, the Sanskrit word for 'wellness', encapsulates the very
              essence of our cafe. At Arogya Cafe, we believe that every plate
              we serve tells a story - a story of passion, dedication, and an
              unwavering commitment to quality. Founded by the dynamic duo Madhu
              and Rajmohan, it is not just a culinary venture; it's a heartfelt
              journey that began in the bustling streets of Bangalore. Our menu
              is a canvas of aromatic spices and locally-sourced ingredients,
              painting a portrait of rich heritage and contemporary delight. At
              Arogya Cafe, every bite tells a story of health, culture, and
              timeless art of South India.
            </p>
            {!show ? (
              <BsChevronDoubleDown
                className="aboutUs-downArrow"
                onClick={() => setShow(true)}
              />
            ) : null}
            {show && (
              <>
                <h1 className="aboutUs-title2">
                  Our Roots: From Hotels to Hearts
                </h1>
                <div
                  className="aboutUS_text"
                  style={{ animation: "growDown 300ms ease-in-out forwards" }}
                >
                  Madhu and Rajmohan, both hailing from the culturally rich
                  tapestry of South India, have a legacy deeply entrenched in
                  the hospitality industry. With a lineage of hotels and PG
                  accommodations in Hyderabad and Bangalore, they understand the
                  true essence of providing not just a place to stay, but an
                  experience that touches the soul. The aroma of spices, the
                  warmth of genuine hospitality, and the love for authentic
                  South Indian cuisine inspired them to embark on a new chapter
                  - the birth of Arogya Cafe.
                </div>
              </>
            )}
          </div>
          {/* </div> */}
        </div>
      </div>

      {/* {menu}  button with line*/}
      <div id="menu" className="menu">
        <h1 className="aboutUs-title">OUR MENU</h1>
        <div className="menuContainer">
          <div className="menuDescription">
            Our menu boasts a captivating selection of dosas, idlis, and rich
            curries, each crafted with authentic spices and love. From crispy
            medu vadas to aromatic coconut chutneys, every dish narrates the
            tale of Southern culinary heritage.
          </div>
          <div className="menuButtonBox">
            <a
              className="menulink"
              href="https://drive.google.com/file/d/1XlDNLp60wjzkX1OnzVmVTX4puDwECVlS/view?usp=drive_link"
              target="_blank"
              download
            >
              <BiFoodMenu className="menuButton" />
            </a>
            <div className="menuButtonText">VIEW MENU</div>
          </div>
        </div>
      </div>

      <div className="bestSellerContainer">
        <h1 className="aboutUs-title">TOP SELLERS</h1>
        <div className="best-desc">
          Crowd Favorites: Discover the Most Loved Cuisines.
        </div>
        <div className="bestSeller">
          <div className="bestSellerItem">
            <img
              className="bestSellerImage"
              src="https://b.zmtcdn.com/data/dish_photos/9ec/f9edb910feb0fdde5035feb8a95959ec.jpg?output-format=webp"
              alt="bestSellerItem"
            />
            <div className="bestSellerItemName">POORI SAAGU</div>
            <div className="bestSellerItemPrice">Rs. 60</div>
          </div>
          <div className="bestSellerItem">
            <img
              className="bestSellerImage"
              src="https://b.zmtcdn.com/data/dish_photos/16b/044a32baedf82444a6a455618242216b.jpg?output-format=webp"
              alt="bestSellerItem"
            />
            <div className="bestSellerItemName">Ghee Sambar Button Idly</div>
            <div className="bestSellerItemPrice">Rs. 60</div>
          </div>
          <div className="bestSellerItem">
            <img
              className="bestSellerImage"
              src="https://b.zmtcdn.com/data/dish_photos/18a/f79e359ae26dddaf1d7fea920475318a.jpg?output-format=webp"
              alt="bestSellerItem"
            />
            <div className="bestSellerItemName">MASALA DOSA</div>
            <div className="bestSellerItemPrice">Rs. 50</div>
          </div>
          <div className="bestSellerItem">
            <img
              className="bestSellerImage"
              src="https://b.zmtcdn.com/data/dish_photos/bf5/5469fe37bf0c787b18b261d496310bf5.jpg?output-format=webp"
              alt="bestSellerItem"
            />
            <div className="bestSellerItemName">Puliyogare</div>
            <div className="bestSellerItemPrice">Rs. 70</div>
          </div>
          <div className="bestSellerItem">
            <img
              className="bestSellerImage"
              src="https://b.zmtcdn.com/data/dish_photos/f73/6a9d0ff56e8082e9958dd32b5266cf73.jpg?output-format=webp"
              alt="bestSellerItem"
            />
            <div className="bestSellerItemName">RAVA IDLY</div>
            <div className="bestSellerItemPrice">Rs. 30</div>
          </div>
          <div className="bestSellerItem">
            <img
              className="bestSellerImage"
              src="https://b.zmtcdn.com/data/dish_photos/6a3/ad5210139399fefec24ea80ea1df26a3.jpg?output-format=webp"
              alt="bestSellerItem"
            />
            <div className="bestSellerItemName">Open Butter Masala Dosa</div>
            <div className="bestSellerItemPrice">Rs. 100</div>
          </div>
        </div>
      </div>
      {/* <div className="order now button">*/}
      <div className="zomato-container">
        <div className="zomato-logo-container">
          <SiZomato className="zomato-logo zomato-noDispla" />
        </div>
        <div className="zomato-button">
          <img src={zomatoBike} className="zomatoBike" />
          <a
            href="https://www.zomato.com/bangalore/the-arogya-cafe-nagawara-bangalore"
            target="_blank"
          >
            <button class="order-now-button">Order Now</button>
          </a>
        </div>
      </div>

      <div className="features-container">
        <h1 className="aboutUs-title">Why Arogya Cafe?</h1>
        <div className="features-sub-container">
          <div className="feature">
            <div className="featureHead">
              <img src={variety} className="feature-icon" />
              <h2 className="featureHeading">Diverse Menu</h2>
            </div>
            <div className="featureDesc">
              Experience an array of delectable dishes that showcase the breadth
              and depth of South Indian gastronomy, from crispy vadas to rich
              Chettinad curries.
            </div>
          </div>
          <div className="feature">
            <div className="featureHead">
              <img src={quality} className="feature-icon" />
              <h2 className="featureHeading">Uncompromising Quality</h2>
            </div>

            <div className="featureDesc">
              Quality is at the core of our identity. We take pride in serving
              you nothing but the best, prepared with utmost care and
              authenticity.
            </div>
          </div>
        </div>
        <div className="features-sub-container">
          <div className="feature">
            <div className="featureHead">
              <img src={convi} className="feature-icon" />
              <h2 className="featureHeading">Convenience</h2>
            </div>
            <div className="featureDesc">
              Bringing the flavors of South India to your table, we offer
              seamless online ordering and delivery services, so you can enjoy
              our culinary delights in the comfort of your own home.
            </div>
          </div>
          <div className="feature">
            <div className="featureHead">
              <img src={value} className="feature-icon" />
              <h2 className="featureHeading">Cost-effective choices</h2>
            </div>
            <div className="featureDesc">
              We understand that amazing dining experiences shouldn't come with
              a hefty price tag. At Arogya Cafe, we take pride in offering you
              an extraordinary culinary voyage without burning a hole in your
              pocket.
            </div>
          </div>
        </div>
      </div>
      {/* <div className="reels">
        <h1>SOCIAL MEDIA</h1>
        <div className="ReelsContainer">
          <ScrollingCarousel>
            <div className="3">
              <iframe
                src="https://www.instagram.com/reel/CtrBzK2gqbq/?utm_source=ig_web_button_share_sheet&igshid=MzRlODBiNWFlZA=="
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="ReelsItem">
              <iframe
                src="https://www.youtube.com/embed/9XjwYkQo7Xs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="ReelsItem">
              <iframe
                src="https://www.youtube.com/embed/9XjwYkQo7Xs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="ReelsItem">
              <iframe
                src="https://www.youtube.com/embed/9XjwYkQo7Xs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </ScrollingCarousel>
        </div>
      </div> */}

      {/* {reviews} divs*/}
      <div className="bestSellerContainer">
        <h1 className="aboutUs-title">TESTIMONIALS</h1>
        <div className="review-desc">Experiences, Flavors, and Praises.</div>
        <div className="review">
          <div className="reviewItem">
            <a
              href="https://goo.gl/maps/HRtciY8fHFktTixH7"
              className="reviewsItem"
            >
              <div className="reviewsItemHeading">Akshay Bangera</div>
              <div className="star-rating">⭐️⭐️⭐️⭐️</div>
              <div className="reviewsItemContent">
                The food was too good 💯. The ambience was great as well. The
                only thing about this place is, the sitting arrangement isn’t
                adequate. If there’s a huge crowd, a lot of people might have
                tostand and eat..
              </div>
            </a>
          </div>
          <div className="reviewItem">
            <a
              href="https://goo.gl/maps/2sJFtigDXssdk1kT9"
              className="reviewsItem"
            >
              <div className="reviewsItemHeading">Ambareesh Cp</div>
              <div className="star-rating">⭐️⭐️⭐️⭐️⭐️</div>
              <div className="reviewsItemContent">
                Delicious veg food restaurant. One of the best in nagawara
                Opposite manyata tech park Quality and taste of the food is top
                notch Very affordable also Masala dos is ma favourite Always too
                crowded.
              </div>
            </a>
          </div>
          <div className="reviewItem">
            <a
              href="https://goo.gl/maps/sHQkjzfKfQGLLVdP9"
              className="reviewsItem"
            >
              <div className="reviewsItemHeading">Akshay Vidap</div>
              <div className="star-rating">⭐️⭐️⭐️⭐️⭐️</div>
              <div className="reviewsItemContent">
                The food taste was good. The ambience was great as well.
                Affordable pricing, good service. North Indian food is also
                available and it’s good. The sitting arrangement isn’t adequate.
                If there’s a huge crowd, a lot of people might have to stand and
                eat and also parking is not adequate.
              </div>
            </a>
          </div>
          <div className="reviewItem">
            <a
              href="https://goo.gl/maps/Dq3edp4fK44TquJ3A"
              className="reviewsItem"
            >
              <div className="reviewsItemHeading">Nidhi Singh</div>
              <div className="star-rating">⭐️⭐️⭐️⭐️</div>
              <div className="reviewsItemContent">
                In simple words, its awesome. I did not find even 1 dish that
                was not good. Everything is great. Only issue is it becomes
                really crowded at lunch and breakfast timings.
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* {footer} map contact us details*/}
      <footer id="location" class="footer">
        <div className="info">
          <div className="locationDetailsItem">
            <div className="locationDetailsItemHeading">Quick Links</div>
            <div className="footerItemContentItem">
              <div
                className="footerItemContentItemHeading"
                onClick={() => scrollToSection("home")}
              >
                Home
              </div>
              <div
                className="footerItemContentItemHeading"
                onClick={() => scrollToSection("aboutUs")}
              >
                About us
              </div>
              <div
                className="footerItemContentItemHeading"
                onClick={() => scrollToSection("menu")}
              >
                Menu
              </div>
            </div>
          </div>

          <div className="locationDetailsItem">
            <div className="locationDetailsItemHeading">LOCATION</div>
            <div className="footerItemContentItemHeading">
              239, 2nd Cross, opposite Manyata Tech Park Road, near Dominos,
              Chanukya Layout, Vyalikaval HBCS Layout, Nagavara, Bengaluru,
              Karnataka 560045
            </div>
          </div>
        </div>
        <div className="locationMap">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.9221155509654!2d77.61791367463293!3d13.040629413361945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17f8df3a75eb%3A0x86495a01d4930ee5!2sThe%20Arogya%20Cafe!5e0!3m2!1sen!2sin!4v1692352664270!5m2!1sen!2sin"
            style={{ border: 0 }}
            allowfullscreen=""
            width="100%"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="footer-logo-div">
          <div className="credits">
            Franchise and catering contact:&nbsp;
            <a
              href="tel:8688563656"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              8688563656
            </a>
            ,&nbsp;
            <a
              href="tel:7708820728"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              7708820728
            </a>
          </div>
          <p class="copyright">© 2023 The Arogya Cafe. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}
