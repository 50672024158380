import './App.css';
import Navbar2 from './components/Navbar/Navbar2';
import Home from './components/Home/Home';

function App() {
  return (
    <div>
      <Navbar2 />
      <Home />
    </div>
  );
}

export default App;
