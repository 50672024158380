import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../logo.png";
import "./Navbar.css"; // Your existing CSS file

export default function Navbar2() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const navbarHeight = 600; // Adjust this value based on your navbar height
    const offset = section.getBoundingClientRect().top - navbarHeight;
    section.scrollIntoView({
      behavior: "smooth",
    });
    setIsMobileNavOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMobileNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${
            isMobileNavOpen ? "show" : ""
          }`}
          style={{ flexGrow: 0 }}
        >
          <ul className="navbar-nav ml-auto navbar-right">
            <li className="nav-item">
              <Link to="/aboutus" className="nav-link">
                <button
                  className="three-d-button"
                  onClick={() => scrollToSection("aboutUs")}
                >
                  About us
                </button>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Menu" className="nav-link">
                <button
                  className="three-d-button"
                  onClick={() => scrollToSection("menu")}
                >
                  Menu
                </button>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/location" className="nav-link">
                <button
                  className="three-d-button"
                  onClick={() => scrollToSection("location")}
                >
                  We are here
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
